import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LOGIN_URL } from '../apiConfig';
import axiosInstance from '../axiosConfig';
import 'bootstrap/dist/css/bootstrap.min.css';
import api from '../services/api';  // Importar la instancia de Axios
import { TailSpin } from 'react-loader-spinner';

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');


    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            const response = await api.post(LOGIN_URL, { username, password });
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('isSupervisor', response.data.is_supervisor);
            if (response.data.is_supervisor) {
                navigate('/supervision/resumen');
            } else {
                navigate('/resumen');
            }
        } catch (error) {
            console.error('Error al iniciar sesión', error);

            if (error.response && error.response.status === 400) {
                setError('Usuario o contraseña incorrectos');
            } else {
                setError('Error al intentar iniciar sesión. Intente nuevamente más tarde.');
            }
        }
        finally{            
            setLoading(false);
        }
    };

    useEffect(() => {
        document.body.className = theme; // Aplica la clase 'light' o 'dark' al body
      }, );
      
    return (
        <div className="container d-flex justify-content-center align-items-center vh-100">

            <div className={`container p-5 rounded " ${theme === "light" ? "bg-light text-black" : "bg-dark text-white"}`} 
                style={{ maxWidth: '400px', width: '100%' }}>
                <div className="text-center mb-4">
                    <h1 className="h1 mb-3 font-weight-normal">La Milkiforma</h1>
                    <h2 className="h5">Iniciar Sesión</h2>
                </div>
                {loading ?  (
                    <div className="d-flex justify-content-center mt-3">
                        <TailSpin color="#007bff" height={50} width={50} />
                    </div>
                        ) : (
                <form onSubmit={handleSubmit}>
                    <div className="form-group mb-3">
                        <input 
                            type="text" 
                            className={`form-control mb-2 ${theme === "light" ? "" : "input-theme-black"}`}
                            data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                            value={username} 
                            onChange={(e) => setUsername(e.target.value)} 
                            placeholder="Usuario" 
                            required 
                        />
                    </div>
                    <div className="form-group mb-3">
                        <input 
                            type="password" 
                            className={`form-control mb-2 ${theme === "light" ? "" : "input-theme-black"}`}
                            data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            placeholder="Contraseña" 
                            required 
                        />
                    </div>
                    <button type="submit" className="btn btn-primary w-100">Ingresar</button>
                </form> )}
                {error && <p className="text-danger text-center mt-3">{error}</p>}
            </div>
        </div>
    );
};

export default LoginPage;
