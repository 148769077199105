import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'; // Importa el icono de calendario

// import { faEye, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
const CalendarSelector = ({ initialDate, onDateChange }) => {
    const [selectedDate, setSelectedDate] = useState(initialDate);
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

    useEffect(() => {
        setSelectedDate(initialDate); // Actualizar el estado interno si initialDate cambia
    }, [initialDate]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        onDateChange(date); // Propagar la fecha seleccionada al componente padre
    };

    return (
        <div className={`input-group ${theme === "light" ? "bg-light text-black" : "bg-dark text-white"}`}>
            <span className={`input-group-text border-0 ${theme === "light" ? "bg-light text-black" : "bg-dark text-white"}`}>
                <FontAwesomeIcon 
                    icon={faCalendarAlt}
                    className={`m-2 ${theme === "light" ? "" : "text-secondary"}`}
                /> 
                </span>
            <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="dd-MM-yyyy"
                maxDate={new Date()}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                className={`form-control ${theme === "light" ? "" : "input-theme-black"}`}
                calendarClassName={theme === "light" ? "" : "calendar-dark"} // Clase personalizada para el calendario
                />
       </div>

    );
};

export default CalendarSelector;
