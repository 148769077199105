import axios from 'axios';

// Crear una instancia de Axios con la configuración base
const api = axios.create({
    baseURL: window.location.origin,  // Cambia esto a la URL base de tu API
});

// Interceptor de respuestas para manejar errores de autenticación
api.interceptors.response.use(
    response => response,  // Retornar la respuesta si todo está bien
    error => {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            // Token inválido, redirigir a la página de inicio de sesión
            localStorage.removeItem('token');
            window.location.href = '/login';  // Redirigir a la página de inicio de sesión
        }
        return Promise.reject(error);
    }
);

export default api;  // Exportar la instancia de Axios para usar en otros componentes
