import React, { useState} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { LOGOUT_URL } from '../apiConfig';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUserCircle} from '@fortawesome/free-solid-svg-icons';
import { Modal, Button } from 'react-bootstrap';
import './Prospeccion.css'; // Asegúrate de que este archivo contiene los estilos necesarios
// Importación de JavaScript de Bootstrap y Popper.js
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const Navbar = () => {
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const headers = { Authorization: `Token ${token}` };
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');


    const handleLogout = async () => {
        localStorage.removeItem('token');
        localStorage.removeItem('selectedDate');
        localStorage.removeItem('isSupervisor');
        try {
            await axios.post(LOGOUT_URL, null, { headers });
        } catch (error) {
            console.error('Error logging out:', error);
        } finally {
            navigate('/');
        }
    };

    const confirmLogout = () => {
        setShowModal(true);
    };
    const toProfile = () => {
        navigate('/profile');
        ;
    };
    const handleClose = () => {
        setShowModal(false);
    };

    const handleConfirmLogout = () => {
        handleLogout();
        setShowModal(false);
    };

    
    return (
        <>
            <nav className={`navbar navbar-expand-lg ${theme === "light" ? "navbar-light bg-light" : "navbar-dark bg-dark"}`}>
                <div className="container">
                    <NavLink className="navbar-brand">
                        La Milkiforma
                    </NavLink>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav me-auto"> {/* Cambiado 'mr-auto' a 'me-auto' para la versión actual de Bootstrap */}
                            <li className="nav-item mx-3">
                                <NavLink className="nav-link" to="/resumen">
                                    Resumen
                                </NavLink>
                            </li>
                            <li className="nav-item mx-3">
                                <NavLink className="nav-link" to="/prospeccion">
                                    Prospección
                                </NavLink>
                            </li>
                            <li className="nav-item mx-3">
                                <NavLink className="nav-link" to="/board">
                                    Tablero
                                </NavLink>
                            </li>
                        </ul>
                        {/* Alineación a la derecha */}
                        <div className="d-flex ms-auto w-25"> {/* 'ms-auto' asegura que el contenido se empuje a la derecha */}
                            <button className="btn btn-outline-primary me-2" onClick={toProfile}>
                                <FontAwesomeIcon icon={faUserCircle} className="me-2" />
                                Perfil
                            </button>
                            <button className="btn btn-outline-danger " onClick={confirmLogout}>
                                <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
                                Logout
                            </button>
                        </div>
                    </div>
                </div>
            </nav>


            <Modal
                show={showModal}
                onHide={handleClose}
                centered
                className={`${theme === 'dark' ? 'modal-dark' : 'modal-light'} custom-modal`} // Clase personalizada
                >
                <Modal.Header
                    closeButton
                    className={`${theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'} custom-modal-header`} // Añadir clase
                >
                    <Modal.Title>Confirmar Logout</Modal.Title>
                </Modal.Header>
                <Modal.Body className={theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'}>
                    ¿Estás seguro que deseas cerrar sesión?
                </Modal.Body>
                <Modal.Footer className={theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-dark'}>
                    <Button variant="secondary" onClick={handleClose}>
                    Cancelar
                    </Button>
                    <Button variant="danger" onClick={handleConfirmLogout}>
                    Cerrar Sesión
                    </Button>
                </Modal.Footer>
                </Modal>

        </>
    );
};

export default Navbar;
