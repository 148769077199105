// src/axiosConfig.js

import axios from 'axios';

// Crear una instancia de Axios
const instance = axios.create({
    baseURL: window.location.origin, // Cambia esta URL si es necesario
    headers: {
        'Content-Type': 'application/json',
    }
});

// Interceptor de solicitudes
instance.interceptors.request.use(request => {
    console.log('Starting Request', request);
    return request;
}, error => {
    return Promise.reject(error);
});

// Interceptor de respuestas
instance.interceptors.response.use(response => {
    console.log('Response:', response);
    return response;
}, error => {
    console.log('Response Error:', error.response);
    return Promise.reject(error);
});

export default instance;
