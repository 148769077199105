import React, { useState, Fragment } from 'react';
import axios from 'axios';
import { IMPORT_CSV_URL } from '../apiConfig';
import { Modal, Button, Table, Form, Pagination, Alert } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

const GestionCSV = ({onClose}) => {
  const [file, setFile] = useState(null);
  const token = localStorage.getItem('token');
  const headers = { Authorization: `Token ${token}` };
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const importarContactos = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    try {
        setLoading(true);
        const response = await axios.post(IMPORT_CSV_URL, formData, { headers });
        alert(response.data.message);
        onClose();
    } catch (error) {
      alert('Error al importar contactos.');
    }
    finally{
        setLoading(false);
    }
  };
  const handleClickOutside = (e) => {
    if (e.target.classList.contains('modal')) {
      onClose();
    }
  };

  return (
    <div className="modal gestion-csv" 
        onClick={handleClickOutside}>
      <div className={`p-4 rounded w-25  ${theme === "light" ? "bg-light text-black" : "bg-dark text-white"}`}>
        <div>
          <h2 className={`h2 ${theme === "light" ? "text-black" : "text-white"}`}>Gestionar Contactos mediante CSV</h2>
          <span className="close" 
            onClick={onClose}>&times;
          </span>
        </div>
        {/* Importar CSV */}
        <div className="importar-csv m-5 d-flex flex-column justify-content-center">
            <input 
                className={` ${theme === "light" ? "" : "input-theme-black w-100 m-3"}`}
                data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                type="file" 
                accept=".csv" 
                onChange={handleFileChange} 
                disabled={loading}
                />
            <button variant=" " 
                className='btn btn-primary w-100 m-3'
                onClick={importarContactos}
                disabled={loading}>
                {loading ? (
                        <Fragment>
                            Cargando <Spinner animation="border" size="sm" />
                        </Fragment>): 'Importar Contactos' }
            </button>
            <button 
                className="secondary-btn w-100 m-3" 
                onClick={onClose}
                disabled={loading}
                >Cerrar</button>
        </div>
      </div>
    </div>
  );
};

export default GestionCSV;
