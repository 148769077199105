import React, { createContext, useContext, useState } from 'react';
import useSummaryData from './useSummaryData';

// Crear el contexto
const SummaryContext = createContext();

// Hook personalizado para usar el contexto
export const useSummary = () => {
    return useContext(SummaryContext);
};

// Componente proveedor de contexto
export const SummaryProvider = ({ children }) => {
    const [selectedDate, setSelectedDate] = useState(() => {
        const storedDate = localStorage.getItem('selectedDate');
        return storedDate ? new Date(storedDate) : new Date();
    });

    const { data, loading, error } = useSummaryData(selectedDate);

    return (
        <SummaryContext.Provider value={{ data, loading, error, selectedDate, setSelectedDate }}>
            {children}
        </SummaryContext.Provider>
    );
};
