import React, { useState, useEffect } from 'react';
import CalendarSelector from './CalendarSelector';
import { useSummary } from './SummaryContext';
import axios from 'axios';
import { PERSONAL_URL } from '../apiConfig';
import './Prospeccion.css'; // Reutilizando el mismo archivo CSS

const Personal = () => {
    const { data, loading, error, selectedDate, setSelectedDate } = useSummary();
    const [successMessage, setSuccessMessage] = useState(null);
    const [saveLoading, setSaveLoading] = useState(false);

    const [actividadFisica, setActividadFisica] = useState(false);
    const [videosNilsmarketing, setVideosNilsmarketing] = useState(false);
    const [lectura, setLectura] = useState(false);
    const personalData = data?.personal;

    useEffect(() => {
        if (!loading && !data?.personal) {
            setActividadFisica(false);
            setVideosNilsmarketing(false);
            setLectura(false);
            setSuccessMessage('No hay datos para la fecha seleccionada.');
        } else if (data?.personal) {
            setActividadFisica(data.personal.actividad_fisica || false);
            setVideosNilsmarketing(data.personal.videos_nilsmarketing || false);
            setLectura(data.personal.lectura || false);
            setSuccessMessage(null);
        }
    }, [data, loading]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        localStorage.setItem('selectedDate', date.toISOString());
    };

    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    const isTodayOrYesterday = (date) => {
        const selected = new Date(date.toDateString());
        return selected.getTime() === today.setHours(0, 0, 0, 0) || selected.getTime() === yesterday.setHours(0, 0, 0, 0);
    };

    const handleSubmit = async () => {
        setSaveLoading(true);
        setSuccessMessage(null);

        const token = localStorage.getItem('token');
        const headers = { Authorization: `Token ${token}` };

        try {
            await axios.post(PERSONAL_URL, {
                actividad_fisica: actividadFisica,
                videos_nilsmarketing: videosNilsmarketing,
                lectura: lectura,
                date: selectedDate.toISOString().split('T')[0],
            }, { headers });

            setSuccessMessage('Datos guardados exitosamente.');
        } catch (error) {
            console.error('Error al guardar los datos:', error);
        } finally {
            setSaveLoading(false);
        }
    };

    return (
        <div className="prospection-container">
            <div className='main_div'>
                <h1>Personal</h1>
                <div className="calendar-section">
                    <h3>Seleccionar Fecha</h3>
                    <CalendarSelector initialDate={selectedDate} onDateChange={handleDateChange} />
                </div>

                {loading && <p className="loading-text">Cargando...</p>}
                {error && <p className="error-text">{error}</p>}
                {successMessage && <p className="success-text">{successMessage}</p>}

                {personalData && (
                    <div className={`prospection-data ${saveLoading ? 'disabled' : ''}`}>
                        <div className="checkbox-section">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={actividadFisica}
                                    onChange={(e) => setActividadFisica(e.target.checked)}
                                    readOnly={!isTodayOrYesterday(selectedDate)}
                                />
                                Realizaste actividad física hoy?
                            </label>
                        </div>
                        <div className="checkbox-section">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={videosNilsmarketing}
                                    onChange={(e) => setVideosNilsmarketing(e.target.checked)}
                                    readOnly={!isTodayOrYesterday(selectedDate)}
                                />
                                Viste algún video de Nilsmarketing hoy?
                            </label>
                        </div>
                        <div className="checkbox-section">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={lectura}
                                    onChange={(e) => setLectura(e.target.checked)}
                                    readOnly={!isTodayOrYesterday(selectedDate)}
                                />
                                Realizaste al menos 30 min de lectura?
                            </label>
                        </div>
                    </div>
                )}

                <button 
                    onClick={handleSubmit} 
                    disabled={!isTodayOrYesterday(selectedDate) || loading || saveLoading}
                    className="primary-btn"
                >
                    {saveLoading ? <div className="spinner"></div> : 'Guardar'}
                </button>
            </div>
        </div>
    );
};

export default Personal;
