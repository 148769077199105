import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { USERS_URL, STUDENT_DATA_URL } from '../apiConfig';
import { TailSpin } from 'react-loader-spinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faArrowLeft, faPencil, faListAlt } from '@fortawesome/free-solid-svg-icons';
import AttendanceModal from './AttendanceModal'; 
import StudentDetailsModal from './StudentDetailsModal'; // Importar el modal
import { start } from '@popperjs/core';

const SupervisorResumen = () => {
    const [students, setStudents] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [studentData, setStudentData] = useState([]);
    const [studentDetails, setStudentDetails] = useState([]);
    const [startDate, setStartDate] = useState(() => {
        const date = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000); // 7 días atrás
        date.setHours(0, 0, 0, 0); // Establecer la hora a 00:00:00
        return date;
    });
    const [endDate, setEndDate] = useState(() => {
        const date = new Date();
        date.setHours(23, 59, 59, 999); // Establecer la hora a 23:59:59
        return date;
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [isViewingDetails, setIsViewingDetails] = useState(false);
    const token = localStorage.getItem('token');
    const headers = { Authorization: `Token ${token}` };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isStudentDetailsModalOpen, setIsStudentDetailsModalOpen] = useState(false); 
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');


    useEffect(() => {
        document.body.className = theme; // Aplica la clase 'light' o 'dark' al body

        const fetchStudents = async () => {
            setLoading(true);
            try {
                const response = await axios.get(USERS_URL, { headers });
                
                // Filtrar estudiantes que tienen allowed en true
                const studentsFiltered = response.data.filter(student => student.allowed);
                setStudents(studentsFiltered);
            } catch (error) {
                
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized, removing token');
                localStorage.removeItem('token'); // Eliminar token si es un 401
                alert('Debes iniciar sesión nuevamente.');
                window.location.href = '/'; // Redirigir a /login
        
            } else {
                    console.error('Error fetching student data:', error);
                }
            } finally {
                setLoading(false);
            }
        };
        fetchStudents();
    }, []);
    
    const fetchStudentData = async (selectedStudent,
        start_date2 = (() => {
          const date = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000); // 7 días atrás
          date.setHours(0, 0, 0, 0); // Establecer la hora a 00:00:00
          return date;
        })(),
        end_date2 = (() => {
          const date = new Date();
          date.setHours(23, 59, 59, 999); // Establecer la hora a 23:59:59
          return date;
        })()
      ) => {
        setLoading(true);
        try {
            const response = await axios.get(STUDENT_DATA_URL, {
                headers,
                params: {
                    student_id: selectedStudent,
                    start_date: start_date2.toISOString(),//.split('T')[0],
                    end_date: end_date2.toISOString()//.split('T')[0],
                },
            });
            const { prospection } = response.data;
            setStudentDetails(response.data)
            console.log(response.data)
            const combinedData = combineDataByDate(prospection,start_date2, end_date2);
            setStudentData(combinedData);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized, removing token');
                localStorage.removeItem('token'); // Eliminar token si es un 401
                alert('Debes iniciar sesión nuevamente.');
                window.location.href = '/'; // Redirigir a /login
        
            } else {
            console.error('Error fetching student data:', error);
        }} finally {
            setIsViewingDetails(true);
            setLoading(false);
        }
    };
    // // Cerrar el modal después de guardar los datos
    // const handleModalClose = () => {
    //     setIsModalOpen(false);
    // }

    const handleDateChange = (newStartDate, newEndDate) => {
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

    const handleFetchLastWeek = () => {
        const newStartDate = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
        newStartDate.setHours(0, 0, 0, 0); // Establecer la hora a 00:00:00

        const newEndDate = new Date();
        newEndDate.setHours(23, 59, 59, 999); // Establecer la hora a 23:59:59

        handleDateChange(newStartDate, newEndDate);
        setTimeout(() => fetchStudentData(selectedStudent, newStartDate, newEndDate), 0); // Espera a que se actualicen las fechas antes de hacer la solicitud
    };

    const handleFetchLastMonth = () => {
        const newStartDate = new Date(Date.now() - 29 * 24 * 60 * 60 * 1000);
        newStartDate.setHours(0, 0, 0, 0); // Establecer la hora a 00:00:00

        const newEndDate = new Date();
        newEndDate.setHours(23, 59, 59, 999); // Establecer la hora a 23:59:59

        handleDateChange(newStartDate, newEndDate);
        setTimeout(() => fetchStudentData(selectedStudent, newStartDate, newEndDate), 0); // Espera a que se actualicen las fechas antes de hacer la solicitud
    };
    
    const handleFilterClick = () => {
        fetchStudentData(selectedStudent, startDate, endDate); // Realiza la solicitud manualmente solo cuando se hace clic en el botón Filtrar
    };

    const combineDataByDate = (prospection, startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const dateMap = new Map();
    
        prospection.forEach((item) => {
            // Extraer solo la parte de la fecha (sin la hora)
            const dateStr = item.date.split('T')[0];
            dateMap.set(dateStr, {
                fecha: dateStr,
                contactos_parte_1_enviada: item.contactos_parte_1_enviada || 0,
                contactos_parte_2_enviada: item.contactos_parte_2_enviada || 0,
                reuniones_realizadas: item.reuniones_realizadas || 0,
            });
        });
    
        const result = [];
        for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
            const dateStr = dt.toISOString().split('T')[0]; // Formatear la fecha sin la hora
            if (!dateMap.has(dateStr)) {
                dateMap.set(dateStr, {
                    fecha: dateStr,
                    contactos_parte_1_enviada: 0,
                    contactos_parte_2_enviada: 0,
                    reuniones_realizadas: 0,
                });
            }
            result.push(dateMap.get(dateStr));
        }
    
        // Ordenar las fechas de manera descendente
        return result.sort((b, a) => new Date(a.fecha) - new Date(b.fecha));
    };
    


    const handleViewDetails = (studentId) => {
        setSelectedStudent(studentId);
        fetchStudentData(studentId) 
        };

    const handleBackToStudentList = () => {
        setIsViewingDetails(false);
    };


    const sumColumn = (key) => {
        return studentData.reduce((sum, row) => sum + (parseInt(row[key], 10) || 0), 0);
    };

    const handleOpenStudentDetailsModal = () => {
        setIsStudentDetailsModalOpen(true); // Abrir modal
    };

    return (
        <div className={`container mt-5 p-4 rounded ${theme === "light" ? "bg-light text-black" : "bg-dark text-white"}`}>
            <div className="d-flex justify-content-between align-items-center mt-4 mb-2">
                <h1 className="mb-0">Supervisión - Resumen de Alumnos</h1>
            </div>
            
            {!isViewingDetails ? (
                <div className={`card p-4 rounded ${theme === "light" ? "bg-light text-black" : "bg-dark text-white"}`}>
                    <div className="mb-3">
                        <h3>Alumnos</h3>
                        <input
                            type="text"
                            placeholder="Buscar por nombre"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className={`form-control mb-2 ${theme === "light" ? "" : "input-theme-black"}`}
                            data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                        />
                        {loading ?  (
                    <div className="d-flex justify-content-center mt-3">
                        <TailSpin color="#007bff" height={50} width={50} />
                    </div>
                        ) : (
                        <ul className="mt-5 list-group">
                            {students.filter((student) => (
                                `${student.first_name} ${student.last_name}`.toLowerCase().includes(searchTerm.toLowerCase())
                                && student.is_supervisor === "Estudiantes")
                            ).map((student) => (
                                    <li key={student.id} className={`border-secondary list-group-item d-flex justify-content-between align-items-center ${theme === "light" ? "bg-light text-black" : "bg-dark text-white"}`}>
                                        {student.first_name} {student.last_name}
                                        <button className="btn btn-sm btn-outline-primary" onClick={() => handleViewDetails(student.id)}>
                                            <FontAwesomeIcon icon={faEye} /> Ver detalles
                                        </button>
                                    </li>
                                ))}
                        </ul>)}
                    </div>
                </div>
            ) : (
                <div className='m-3'>
                    <div className='d-flex'>
                        <button className="btn btn-outline-primary m-4" onClick={handleBackToStudentList}>
                            <FontAwesomeIcon icon={faArrowLeft} /> Volver a la lista de alumnos
                        </button>

                        <button className="btn btn-primary m-4" onClick={() => setIsModalOpen(true)}>
                            Asistencia de alumnos</button>
                            {isModalOpen && (
                                <AttendanceModal 
                                    show={isModalOpen} 
                                    handleClose={() => setIsModalOpen(false)} 
                                    student={students.find((s) => s.id === selectedStudent)} 
                                />
                            )}
                        <button onClick={handleOpenStudentDetailsModal} className="btn m-4 btn-outline-info">
                            Ver detalles del estudiante
                        </button>
                    </div>
                    <h3>
                    Datos de {students.find((s) => s.id === selectedStudent)?.first_name ?? 'N/A'}{' '}
                    {students.find((s) => s.id === selectedStudent)?.last_name ?? 'N/A'}
                    </h3>
                    <div className="student-data m-auto">   
                    <div className="m-3">
                        {/* <p>
                        Último inicio de sesión:&nbsp; 
                        {students.find((s) => s.id === selectedStudent)?.last_login 
                            ? students.find((s) => s.id === selectedStudent)?.last_login.toString().split('.')[0].replace('T', ' ') 
                            : 'No disponible'}
                        </p> */}
                        <p>
                        Ingreso al Milki Challenge:&nbsp; 
                        {students.find((s) => s.id === selectedStudent)?.date_joined 
                            ? students.find((s) => s.id === selectedStudent)?.date_joined.toString().split('T')[0] 
                            : 'No disponible'}
                        </p>
                        <p>
                            Días en el Milki Challenge:&nbsp;
                            {(() => {
                                const student = students.find((s) => s.id === selectedStudent);
                                if (student?.date_joined) {
                                const joinedDate = new Date(student.date_joined);
                                if (!isNaN(joinedDate)) {
                                    const diffInMs = new Date() - joinedDate;
                                    return Math.floor(diffInMs / (1000 * 60 * 60 * 24));
                                }
                                }
                                return 'No disponible'; // Para fecha inválida o no existente
                            })()}
                        </p>
                    </div>
                    <div className="m-3"> 
                        <p>
                        Nicho:&nbsp;
                        {studentDetails?.student_details?.find((s) => s.user === selectedStudent)?.niche ?? 'No disponible'}
                        </p>
                        <p>
                        País de prospección:&nbsp;
                        {studentDetails?.student_details?.find((s) => s.user === selectedStudent)?.prosp_country ?? 'No disponible'}
                        </p>
                    </div>
                    <div className="m-3">
                        <p>
                        Último cliente cerrado:&nbsp;
                        {studentDetails?.client_data?.fecha_ultimo_cliente?.created_at 
                            ? new Date(studentDetails.client_data.fecha_ultimo_cliente.created_at).toISOString().split('T')[0] 
                            : 'No disponible'}
                        </p>
                        <p>
                        Cantidad de clientes:&nbsp;
                        {studentDetails?.client_data?.cantidad_clientes 
                            ?   studentDetails.client_data.cantidad_clientes
                            : 'No disponible'}
                        </p>
                    </div>
                    {/* Modal para los detalles del estudiante */}
                    {isStudentDetailsModalOpen && (
                        <StudentDetailsModal
                        studentDetails={studentDetails}
                        setStudentDetails={setStudentDetails}
                        handleClose={() => setIsStudentDetailsModalOpen(false)}
                        selectedStudent={selectedStudent}
                        fetchStudentData={fetchStudentData}
                        />
                    )}
                    </div>

                    <div className="d-flex mb-3">
                        <div className="me-4">
                            <label>Fecha Inicio:</label>
                            <input
                                type="date"
                                value={startDate.toISOString().split('T')[0]}
                                className={`form-control mb-2 ${theme === "light" ? "" : "input-theme-black"}`}
                                data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const regex = /\d{4}-\d{2}-\d{2}/
                                    if (regex.test(inputValue) && inputValue.length <= 10) {
                                        const selectedDate = new Date(`${inputValue}T00:00:00`); // Mantén la hora en UTC (medianoche)
                                        selectedDate.setUTCHours(23, 59, 59, 999); // Ajusta la hora a  UTC
                                        setStartDate(selectedDate);
                                    }
                                  }}
                            />
                        </div>
                        <div className="me-4">
                            <label>Fecha Fin:</label>
                            <input
                                type="date"
                                value={endDate.toISOString().split('T')[0]}
                                className={`form-control mb-2 ${theme === "light" ? "" : "input-theme-black"}`}
                                data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const regex = /\d{4}-\d{2}-\d{2}/
                                    if (regex.test(inputValue) && inputValue.length <= 10) {         
                                        const selectedDate = new Date(`${inputValue}T00:00:00`); // Mantén la hora en UTC (medianoche)
                                        selectedDate.setUTCHours(23, 59, 59, 999); // Ajusta la hora a 23:59:59 UTC
                                        setEndDate(selectedDate);
                                    }
                                  }}
                            />
                        </div>
                        <div className="d-flex  align-middle">
                            <button onClick={handleFilterClick} className="btn btn-sm btn-primary">Filtrar</button>
                            <button onClick={handleFetchLastWeek} className="btn btn-sm btn-secondary ms-2">Última Semana</button>
                        
                            <button onClick={handleFetchLastMonth} className="btn btn-sm btn-secondary ms-2">Último Mes</button>
                        </div>
                    </div>
                {studentData.length > 0 ? (loading ?  (
                    <div className="d-flex justify-content-center mt-3">
                        <TailSpin color="#007bff" height={50} width={50} />
                    </div>
                        ) : 
                        <table className={`table p-5 ${theme === "light" ? "bg-light text-black" : "table-dark"}`}>
                            <thead className={`${theme === "light" ? "table-success" : "thead-dark"}`}>
                                <tr>
                                    <th>Fecha</th>
                                    <th>1ra Parte Enviada</th>
                                    <th>2da Parte Enviada</th>
                                    <th>Reuniones Realizadas</th>
                                </tr>
                            </thead>
                            <tbody>
                                {studentData.map((data, index) => (
                                    <tr key={index}>
                                        <td>{data.fecha}</td>
                                        <td>{data.contactos_parte_1_enviada}</td>
                                        <td>{data.contactos_parte_2_enviada}</td>
                                        <td>{data.reuniones_realizadas}</td>
                                    </tr>
                                ))}
                                <tr className={`${theme === "light" ? "table-secondary" : "thead-dark"}`}>
                                    <td>Total</td>
                                    <td>{sumColumn('contactos_parte_1_enviada')}</td>
                                    <td>{sumColumn('contactos_parte_2_enviada')}</td>
                                    <td>{sumColumn('reuniones_realizadas')}</td>
                                </tr>
                            </tbody>
                        </table>
                    ) : (
                        <p className="text-danger">No se encontraron datos para este estudiante en el rango de fechas seleccionado.</p>
                    )} 
                </div>
            )}
        </div>
    );
};

export default SupervisorResumen;
