import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Estilos por defecto del calendario
import axios from 'axios';
import AttendanceModal from './AttendanceModal'; // Importar el modal
import { VIEW_ATTENDANCE_URL, ATTENDANCE_DETAILS_URL } from '../apiConfig';


const AttendanceCalendar = () => {
    const [date, setDate] = useState(new Date());
    const [classesByDay, setClassesByDay] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [attendance, setAttendance] = useState([]); // Para guardar los datos de asistencia
    const [isModalOpen, setIsModalOpen] = useState(false); // Para manejar el estado del modal
    const [selectedStudent, setSelectedStudent] = useState(null); // Alumno seleccionado para el modal
    const [selectedClass, setSelectedClass] = useState(""); // Clase seleccionada
    const token = localStorage.getItem('token');
    const headers = { Authorization: `Token ${token}` };
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

    // Función para obtener las clases del mes seleccionado
    const fetchClasses = async (year, month) => {
        try {
            setLoading(true); // Iniciar la carga
            const response = await axios.get(`${VIEW_ATTENDANCE_URL.replace('<int:year>', year).replace('<int:month>', month)}`, { headers });
            setClassesByDay(response.data); // Guardar las clases en el estado
            setError(null);

        } catch (error) {
            console.error("Error fetching classes:", error);
            setError("Error fetching classes");
        } finally {
            setLoading(false); // Finalizar la carga
        }
    };

    // Función para obtener asistencia de un día seleccionado
    const fetchAttendance = async (classname, selectedDate) => {
        try {
            setLoading(true); // Iniciar la carga

            const formattedDate = selectedDate.toISOString().split('T')[0]; // Formatear la fecha para la solicitud
            const response = await axios.get(`${ATTENDANCE_DETAILS_URL.replace('<str:class_name>', classname).replace('<str:date>', formattedDate)}`, { headers });
            setAttendance(response.data); // Guardar la asistencia en el estado
            console.log(response.data)
            setError(null);

        } catch (error) {
            console.error("Error fetching attendance:", error);
            setError("Error fetching attendance");
        }finally {
            setLoading(false); // Finalizar la carga
        }
    };

    // Cada vez que el mes cambia, actualizamos las clases
    useEffect(() => {
        document.body.className = theme; // Aplica la clase 'light' o 'dark' al body
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // Los meses en JS empiezan en 0
        fetchClasses(year, month);
    }, [date]);

    const handleDayClick = (date) => {
        const dayClasses = classesByDay.find(classItem => {
            const classDate = new Date(classItem.date);
            return (
                classDate.getFullYear() === date.getFullYear() &&
                classDate.getMonth() === date.getMonth() &&
                classDate.getDate() === date.getDate()
            );
        });
    
        if (dayClasses) {
            const className = dayClasses.statistic;  // Obtener el nombre de la clase
            // const formattedDate = date.toISOString().split('T')[0];  // Formatear la fecha

            fetchAttendance(className, date);  // Llamar a la función de fetch
            setSelectedClass(`${className} - ${date.toDateString()}`); // Actualizar el nombre de la clase

        }
    };
    

    // Función para renderizar el contenido de cada celda del calendario
    const renderTileContent = ({ date, view }) => {
        if (view === 'month') {
            const dayClasses = classesByDay.filter(classItem => {
                // console.log(classItem)
                const classDate = new Date(classItem.date);
                return (
                    classDate.getFullYear() === date.getFullYear() &&
                    classDate.getMonth() === date.getMonth() &&
                    classDate.getDate() === date.getDate()
                );
            });

            return (
                <div>
                    {dayClasses.map((classItem, index) => (
                        <div key={index} className='d-flex flex-column p-2 align-items-start'>
                            <p> {classItem.statistic} </p>
                            <p> Alumnos: {classItem.total_students}  </p>
                            <p className='align-self-center text-danger'> {(classItem.total_students / classItem.total_classes * 100).toFixed(2)}% </p>
                        </div>
                    ))}
                </div>
            );
        }
    };

    // Función para manejar el cambio de mes
    const handleActiveStartDateChange = ({ activeStartDate }) => {
        setDate(activeStartDate); // Actualizar el mes y año
    };

    return (
        <div>
            {loading ? (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <p>Cargando...</p> {/* Aquí puedes reemplazar con un spinner si lo prefieres */}
                </div>
            ) : (
                <>
                    <div className={`container mt-5 w-100 h-100 p-4 d-flex flex-column justify-content-center ${theme === "light" ? "bg-light text-black" : "bg-dark text-white"}`}>

                        <h1 className='h1 p-5 m-auto '>Calendario de Clases</h1>
                        {error && <p style={{ color: 'red' }}>{error}</p>}

                        <div className='container d-flex flex-column justify-content-center'>
                            <div className=" d-flex flex-column justify-content-center p-4 ">  
                                <h3 className='h3 p-4 align-self-center'>Selecciona un día</h3>
 
                                <Calendar
                                    className={`calendar-container w-100  ${theme === 'dark' ? 'bg-dark text-white' : 'bg-light text-black'}`}
                                    tileClassName={` ${theme === 'dark' ? 'bg-dark text-white border' : 'bg-light text-black border border-dark' } `}
                                    onChange={handleDayClick}  // Manejar el clic en el día
                                    onActiveStartDateChange={handleActiveStartDateChange} // Para manejar el cambio de mes
                                    value={date}
                                    tileContent={renderTileContent} // Mostrar contenido en los días
                                />
                            </div>    
                            <div className="d-flex flex-column justify-content-center"> 
                                <h3 className='h3 p-4 align-self-center'>{selectedClass}</h3>
                                {attendance.length === 0 ? (
                                    <p>No hay asistencia registrada para este día.</p>
                                ) : (
                                    <table className={`table table-striped ${theme === 'dark' ? 'table-dark' : ''}`}>
                                        <thead>
                                            <tr>
                                                <th>Nombre del alumno</th>
                                                <th>Observaciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {attendance.map((student, index) => (
                                                <tr key={index} 
                                                    onClick={() => {
                                                        student.first_name = student.name
                                                        setSelectedStudent(student.id);
                                                        setIsModalOpen(true); // Abrir el modal con el estudiante seleccionado
                                                }}
                                                style={{ cursor: 'pointer'}}>
                                                    <td>
                                                        {student.name}
                                                    </td>
                                                    <td>{student.observations || 'Sin observaciones'}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Modal para mostrar la información del estudiante */}
                    {isModalOpen && (
                        <AttendanceModal
                            show={isModalOpen}
                            handleClose={() => setIsModalOpen(false)}
                            student={attendance.find((s) => s.id === selectedStudent)}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default AttendanceCalendar;
