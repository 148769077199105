import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PROSPECTION_URL, SUMMARY_URL } from '../apiConfig';
import './Prospeccion.css'; 
import ProspeccionModal from './ProspeccionModal'; 
import 'bootstrap/dist/css/bootstrap.min.css';

const Prospeccion = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [saveLoading, setSaveLoading] = useState(false);
    const [studentData, setStudentData] = useState([]);
    const [startDate, setStartDate] = useState(() => {
        const date = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000); // 7 días atrás
        date.setHours(0, 0, 0, 0); // Establecer la hora a 00:00:00
        return date;
    });
    const [endDate, setEndDate] = useState(() => {
        const date = new Date();
        date.setHours(23, 59, 59, 999); // Establecer la hora a 23:59:59
        return date;
    });
    const [isModalOpen, setIsModalOpen] = useState(false);

    const token = localStorage.getItem('token');
    const headers = { Authorization: `Token ${token}` };
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

    // Obtener los datos de prospección del alumno
    useEffect(() => {
        document.body.className = theme; // Aplica la clase 'light' o 'dark' al body

        fetchStudentData();
    }, []);

    const fetchStudentData = async (
        start_date2 = (() => {
          const date = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000); // 7 días atrás
          date.setHours(0, 0, 0, 0); // Establecer la hora a 00:00:00
          return date;
        })(),
        end_date2 = (() => {
          const date = new Date();
          date.setHours(23, 59, 59, 999); // Establecer la hora a 23:59:59
          return date;
        })()
      ) => {
        setSaveLoading(true);
        try {
            const response = await axios.get(SUMMARY_URL, {
                headers,
                params: {
                    start_date: start_date2.toISOString(),//.split('T')[0],,
                    end_date: end_date2.toISOString()//.split('T')[0],
                },
            });
            setStudentData(response.data);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized, removing token');
                localStorage.removeItem('token'); // Eliminar token si es un 401
                alert('Debes iniciar sesión nuevamente.');
                window.location.href = '/'; // Redirigir a /login
        
            } else {
            console.error('Error fetching student data:', error);
            }
        } finally {
            setSaveLoading(false);
        }
    };

    // Filtrar los datos por el rango de fechas seleccionado
    const handleFilterClick = () => {
        fetchStudentData(startDate, endDate);
    };

    // Obtener los datos de la última semana
    const handleFetchLastWeek = () => {
        const newStartDate = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
        newStartDate.setHours(0, 0, 0, 0); // Establecer la hora a 00:00:00

        const newEndDate = new Date();
        newEndDate.setHours(23, 59, 59, 999); // Establecer la hora a 23:59:59

        fetchStudentData(newStartDate, newEndDate)
    };

    // Obtener los datos del último mes
    const handleFetchLastMonth = () => {
        const newStartDate = new Date(Date.now() - 29 * 24 * 60 * 60 * 1000);
        newStartDate.setHours(0, 0, 0, 0); // Establecer la hora a 00:00:00

        const newEndDate = new Date();
        newEndDate.setHours(23, 59, 59, 999); // Establecer la hora a 23:59:59

        fetchStudentData(newStartDate, newEndDate)
    };

    // Calcular las sumatorias de las columnas
    const sumColumn = (key) => {
        return studentData.prospection.reduce((sum, row) => sum + (parseInt(row[key], 10) || 0), 0);
    };

    // Cerrar el modal después de guardar los datos
    const handleModalClose = () => {
        setIsModalOpen(false);
        // fetchStudentData(); // Actualizar los datos después de guardar en el modal
    };

    if (saveLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        // <div className="prospection-container">
        <div className={`container mt-5 p-5 ${theme === "light" ? "bg-light text-black" : "bg-dark text-white"}`}>

            <div className='main_div'>
                <h1>Prospección</h1>
                <div className='date-container d-flex justify-content-center m-2'>
                    <button className="btn btn-primary mt-4 " onClick={() => setIsModalOpen(true)}>Agregar o modificar datos</button>
                    {isModalOpen && <ProspeccionModal 
                    onClose={handleModalClose} 
                    startDate={startDate}   // 
                    endDate={endDate}
                    studentData={studentData}
                    setStudentData={setStudentData}    
                    backdrop="true"   
                    saveLoading = {saveLoading}
                    />} 
                </div>
                <div className='m-4 d-flex justify-content-between '>
                    <div className='d-flex align-items-end'>
                        <div className="">
                            <label>Fecha Inicio:</label>
                            <input
                                type="date"
                                maxDate={new Date()}
                                value={startDate.toISOString().split('T')[0]}
                                className={`form-control ${theme === "light" ? "" : "input-theme-black"}`}
                                data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const regex = /\d{4}-\d{2}-\d{2}/
                                    if (regex.test(inputValue) && inputValue.length <= 10) {
                                        const selectedDate = new Date(`${inputValue}T00:00:00`); // Mantén la hora en UTC (medianoche)
                                        selectedDate.setUTCHours(23, 59, 59, 999); // Ajusta la hora a  UTC
                                        setStartDate(selectedDate);
                                    }
                                }}
                            />
                        </div>
                        <div className="ms-4 ">
                            <label>Fecha Fin:</label>
                            <input
                                type="date"
                                maxDate={new Date()}
                                className={`form-control ${theme === "light" ? "" : "input-theme-black"}`}
                                data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                                value={endDate.toISOString().split('T')[0]}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const regex = /\d{4}-\d{2}-\d{2}/
                                    if (regex.test(inputValue) && inputValue.length <= 10) {         
                                        const selectedDate = new Date(`${inputValue}T00:00:00`); // Mantén la hora en UTC (medianoche)
                                        selectedDate.setUTCHours(23, 59, 59, 999); // Ajusta la hora a 23:59:59 UTC
                                        setEndDate(selectedDate);
                                    }
                                }}
                            />
                        </div>
                        <div className="ms-4">
                            <button onClick={handleFilterClick} className="btn btn-primary m-0">Filtrar</button>
                        </div>
                    </div>
                
                    <div className='d-flex align-items-end'>
                        <div className="">
                            <button onClick={handleFetchLastWeek} className="btn btn-secondary m-0">Última Semana</button>
                        </div>
                        <div 
                            className="ms-4">
                            <button onClick={handleFetchLastMonth} className="btn btn-secondary m-0">Último Mes</button>
                        </div>
                    </div>
                </div>
                {studentData.prospection && studentData.prospection.length > 0 ? (
                    <table className={`table  ${theme === "light" ? "bg-light text-black" : "table-dark"}`}>
                        <thead className={`${theme === "light" ? "table-success" : "thead-dark"}`}>
                            <tr>
                                <th>Fecha</th>
                                <th>1ra Parte Enviada</th>
                                <th>2da Parte Enviada</th>
                                <th>2da Parte Respondida</th>
                                <th>Reuniones Realizadas</th>
                            </tr>
                        </thead>
                        <tbody>
                            {studentData.prospection.map((data, index) => (
                                <tr key={index}>
                                    <td>{new Date(data.date).toLocaleDateString()}</td>
                                    <td>{data.contactos_parte_1_enviada}</td>
                                    <td>{data.contactos_parte_2_enviada}</td>
                                    <td>{data.contactos_parte_2_respondida}</td>
                                    <td>{data.reuniones_realizadas}</td>
                                </tr>
                            ))}
                            {/* className="table-secondary" */}
                            <tr className={`${theme === "light" ? "table-secondary" : "thead-dark"}`}>
                                <td>Total</td>
                                <td>{sumColumn('contactos_parte_1_enviada')}</td>
                                <td>{sumColumn('contactos_parte_2_enviada')}</td>
                                <td>{sumColumn('contactos_parte_2_respondida')}</td>
                                <td>{sumColumn('reuniones_realizadas')}</td>
                            </tr>
                        </tbody>
                    </table>
                ) : (
                    <p className="text-danger mt-3">No se encontraron datos para el rango de fechas seleccionado.</p>
                )}
            </div>
        </div>
    );
};

export default Prospeccion;
