import { useState, useEffect } from 'react';
import axios from 'axios';
import { RESUMEN_URL, SUMMARY_URL } from '../apiConfig';

const useSummaryData = (selectedDate) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('token');

        // No realizar la solicitud si no hay token
        if (!token) {
            setError('No autorizado. Debes iniciar sesión.');
            setLoading(false);
            return;
        }

        const headers = { Authorization: `Token ${token}` };

        const fetchData = async () => {
            // setLoading(true);
            try {
                const response = await axios.get(`${SUMMARY_URL}?date=${selectedDate}`, { headers });
                setData(response.data);
            } catch (error) {
                setError('Error al obtener los datos');
            } finally {
                setLoading(false);
                setError(null);
            }
        };

        // fetchData();
    }, [selectedDate]);

    return { data, loading, error };
};

export default useSummaryData;
