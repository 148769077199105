import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { CLIENT_LIST_CREATE_URL, CLIENTS_URL } from '../apiConfig';
import './ClientModal.css'; // Importa el archivo CSS para estilizar el modal

const ClientModal = ({ onClose, onClientChange }) => {
  const [clients, setClients] = useState([]);
  const [clientName, setClientName] = useState('');
  const [payment, setPayment] = useState('');
  const [date, setDate] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editingClientId, setEditingClientId] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const token = localStorage.getItem('token');
  const headers = { Authorization: `Token ${token}` };
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);
      try {
        const response = await axios.get(CLIENTS_URL, { headers });
        setClients(response.data);
      } catch (error) {
        console.error('Error fetching clients', error);
      } finally {
        setLoading(false);
      }
    };
    fetchClients();
  }, []);

  const handleAddClient = async () => {
    if (!clientName || !payment || !date) {
      setErrorMessage('Por favor, completa todos los campos antes de agregar un cliente.');
      return;
    }

    setLoading(true);

    try {
      const formattedDate = new Date(date).toISOString().split('T')[0];
      const response = await axios.post(CLIENTS_URL, {
        client_name: clientName,
        monthly_payment: payment,
        created_at: formattedDate,
      }, { headers });

      setClients([...clients, response.data]);
      setClientName('');
      setPayment('');
      setDate('');
      setErrorMessage('');
      setSuccessMessage('Cliente agregado correctamente.');
    } catch (error) {
      console.error('Error adding client', error);
      setErrorMessage('Error al agregar el cliente. Intenta nuevamente.');
    } finally {
      setLoading(false);
      setTimeout(() => setSuccessMessage(''), 3000);
    }
  };

  const handleEditClient = async () => {
    if (!clientName || !payment || !date) {
      setErrorMessage('Por favor, completa todos los campos antes de guardar los cambios.');
      return;
    }

    setLoading(true);

    try {
      const formattedDate = new Date(date).toISOString().split('T')[0];
      const response = await axios.put(`${CLIENT_LIST_CREATE_URL.replace('<int:pk>', editingClientId)}`, {
        client_name: clientName,
        monthly_payment: payment,
        created_at: formattedDate,
      }, { headers });

      const updatedClients = clients.map(client => (client.id === editingClientId ? response.data : client));
      setClients(updatedClients);
      setClientName('');
      setPayment('');
      setDate('');
      setIsEditing(false);
      setEditingClientId(null);
      setErrorMessage('');
      setSuccessMessage('Cliente editado correctamente.');
    } catch (error) {
      console.error('Error editing client', error);
      setErrorMessage('Error al editar el cliente. Intenta nuevamente.');
    } finally {
      setLoading(false);
      setTimeout(() => setSuccessMessage(''), 3000);
    }
  };

  const handleDeleteClient = async (id) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar este cliente?')) {
      setLoading(true);
      try {
        await axios.delete(`${CLIENT_LIST_CREATE_URL.replace('<int:pk>', id)}`, { headers });
        setClients(clients.filter(client => client.id !== id));
        setSuccessMessage('Cliente eliminado correctamente.');
      } catch (error) {
        console.error('Error deleting client', error);
        setErrorMessage('Error al eliminar el cliente. Intenta nuevamente.');
      } finally {
        setLoading(false);
        setTimeout(() => setSuccessMessage(''), 3000);
      }
    }
  };

  const startEditClient = (client) => {
    setClientName(client.client_name);
    setPayment(client.monthly_payment);
    const formattedDate = new Date(client.created_at).toISOString().split('T')[0];
    setDate(formattedDate);
    setIsEditing(true);
    setEditingClientId(client.id);
    setErrorMessage('');
  };
  const handleClickOutside = (e) => {
    if (e.target.classList.contains('modal')) {
      onClose();
    }
  };
  
  return (

    <div className="modal" onClick={handleClickOutside}>

      <div className={`p-4 rounded w-50 ${theme === "light" ? "bg-light text-black" : "bg-dark text-white"}`}>

        <span className="close" 
              onClick={onClose}>&times;
        </span>
        <h2 className={`h2 ${theme === "light" ? "text-black" : "text-white"}`}>Gestionar Clientes</h2>
        <div>
            <>
              <div className="form-group">
                <label>Nombre:</label>
                <input 
                placeholder='Ingrese el nombre'
                type="text" 
                value={clientName} 
                onChange={e => setClientName(e.target.value)} 
                className={`input ${theme === "light" ? "" : "input-theme-black"}`}/>
              </div>
              <div className="form-group">
                <label>Pago mensual:</label>
                <input type="number" 
                placeholder='Ingrese el monto'
                value={payment} 
                onChange={e => setPayment(e.target.value)} 
                className={`input ${theme === "light" ? "" : "input-theme-black"}`}/>
              </div>
              <div className="form-group">
                <label>Fecha:</label>
                <input type="date" 
                  value={date} 
                  onChange={e => setDate(e.target.value)} 
                  className={`input-date ${theme === "light" ? "" : "input-theme-black"}`}
                  data-bs-theme={`${theme === "light" ? "light" : "dark"}`}
                  />
              </div>
              <button className="primary-btn" onClick={isEditing ? handleEditClient : handleAddClient}>
                {isEditing ? 'Guardar Cambios' : 'Agregar Cliente'}
              </button>
              {errorMessage && <p className="error-message ">{errorMessage}</p>}
              {successMessage && <p className="success-message ">{successMessage}</p>}
            </>
          
        </div>
        <ul className="client-list">
          {clients.length > 0 ? (
            clients.map(client => (
              <li key={client.id} className="client-item">
                <div>
                  <strong>{client.client_name}</strong> - ${client.monthly_payment} - {new Date(client.created_at).toLocaleDateString('es-ES')}
                </div>
                <div className="actions">
                  <button onClick={() => startEditClient(client)}>
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  <button onClick={() => handleDeleteClient(client.id)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </div>
              </li>
            ))
          ) : (!loading ?
            <li>No hay clientes registrados</li> : <div className="d-flex justify-content-center align-items-center" style={{ height: '10vh' }}>
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
          )}
        </ul>
        <button className="secondary-btn" onClick={onClose}>Cerrar</button>
      </div>
    </div>
  );
};

export default ClientModal;
